<script setup lang="ts">
import { showModal } from '~/store/modal/AuditModal'

interface Props {
  contrastBackground?: boolean
  title?: string
  description?: string
  checks?: string[]
  button?: string
  free?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
  title: 'Сервис для продвижения в картах',
  description: 'Ранг помогает расти в рейтингах, получать настоящие отзывы и больше клиентов из карт.',
  checks: ['Изучим карточки компании на картах', 'Сравним с конкурентами', 'Подготовим шаги для улучшения'],
  button: 'Получить аудит карточек на картах',
  free: false,
})

const website = ref('')
const loading = ref(false)
</script>

<template>
  <section>
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full overflow-x-hidden">
      <div class="max-w-[1012px] px-4 mx-auto pt-8 pb-16 md:pt-10 md:pb-24 flex md:flex-row flex-col gap-10">
        <div>
          <h1 v-if="title === 'Сервис для продвижения в картах'" class="lg:text-h1 text-m-h1 flex flex-wrap items-center gap-x-2">
            <span>{{ title.split(' ')[0] }}</span> <!-- <span
              v-if="!free"
              class="px-4  py-2 md:py-4 text-center text-primary lg:text-primary-32 bg-green-500/20 rounded-md"
            >от 23 т.р. в месяц</span> -->
            {{ title.substring(title.split(' ')[0].length) }}
          </h1>
          <h1 v-else class="lg:text-h1 text-m-h1 flex flex-wrap items-center gap-x-2">
            {{ title }}
          </h1>
          <slot name="title" />

          <p class="lg:text-primary-large text-m-primary-large mt-2 lg:mt-5 !font-normal">
            {{ description }}
          </p>
          <form class="flex lg:flex-row flex-col items-center gap-2 md:gap-4 mt-4 lg:mt-10" @submit.prevent="showModal(website); website = ''">
            <input
              v-model="website"
              required
              class="w-full lg:max-w-[461px] max-w-full  text-black border px-3 h-[48px] rounded-md hover:border hover:border-black focus:outline-0 focus:border-black disabled:text-secondary-500 disabled:hover:cursor-not-allowed disabled:hover:border-transparent duration-150"
              placeholder="Адрес сайта"
              type="text"
            >
            <RButton class="lg:w-auto w-full" :is-loading="loading" type="submit">
              {{ button }}
            </RButton>
          </form>
          <div class="flex flex-row md:block items-center relative">
            <div class="flex md:flex-row flex-col gap-4 md:gap-14 mt-14 w-1/2 md:w-auto">
              <div v-for="(check, index) in checks" :key="index" class="flex flex-col gap-2 text-primary-small">
                <Icon class="text-green-500 text-primary-large" name="mdi:check" />
                <span v-html="check" />
              </div>
            </div>
            <!-- <div>
              <NuxtImg
                format="webp"
                class="hidden md:block absolute md:-bottom-[95px] md:left-[400px] md:min-w-[650px] lg:-bottom-[95px] lg:left-[690px] max-h-[620px] lg:h-[620px] object-scale-down"
                src="/img_first.png"
                alt="steps"
                quality="40"
                loading="lazy"
              />
            </div> -->
            <div>
              <NuxtImg
                format="webp"
                class="hidden md:block absolute md:-bottom-[96px] md:left-[510px] md:w-[450px] lg:w-[650px] lg:-bottom-[95px] lg:left-[690px] max-h-[620px] lg:h-[620px] object-scale-down"
                src="/img_first.png"
                alt="steps"
                quality="40"
                loading="lazy"
              />
            </div>
          </div>
          <NuxtImg
            format="webp"
            loading="lazy"
            fit="contain" class="absolute top-[510px] sm:top-[440px] -right-[0px] h-[463px] md:hidden object-left-bottom"
            src="/img_first1.png"
            alt="steps"
          />
        </div>
        <div class="hidden md:block opacity-0 h-[100px] w-[500px]" />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
</style>
